// api.js
import axios from 'axios';

const BASE_URL =  'http://137.184.71.161/api';

export const api = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  xsrfCookieName: 'XSRF-TOKEN',
  xsrfHeaderName: 'X-XSRF-TOKEN',
});

let isRefreshing = false;
let refreshSubscribers = [];

const refreshToken = async () => {
  try {
    const response = await api.post('/auth/refresh-token');
    return response.data.accessToken; // In case the server sends a new token
  } catch (error) {
    console.error('Token refresh failed:', error);
    throw error;
  }
};


export const logout = async () => {
  try {
    await api.post('/auth/logout');
    // Clear any client-side state
    localStorage.removeItem('user');
    // Redirect to login page
    window.location.href = '/login';
  } catch (error) {
    console.error('Logout failed:', error);
    // Even if the server request fails, clear local state and redirect
    localStorage.removeItem('user');
    window.location.href = '/login';
  }
};


api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 401 && !originalRequest._retry) {
      if (!isRefreshing) {
        isRefreshing = true;

        try {
          const newToken = await refreshToken();
          if (newToken) {
            api.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
          }
          refreshSubscribers.forEach((callback) => callback(newToken));
          originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
          return api(originalRequest);
        } catch (refreshError) {
          await logout();
          return Promise.reject(refreshError);
        } finally {
          isRefreshing = false;
          refreshSubscribers = [];
        }
      }

      return new Promise((resolve) => {
        refreshSubscribers.push((token) => {
          originalRequest.headers['Authorization'] = `Bearer ${token}`;
          resolve(api(originalRequest));
        });
      });
    }

    return Promise.reject(error);
  }
);

