import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate,Link } from "react-router-dom";
import EmptyMenu from '../components/emptyMenu'
import {ChevronLeft,Loader2 } from 'lucide-react';

function CategorySelector() {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(
        "http://137.184.71.161/api/categories"
      )
      .then((response) => {
        setCategories(response.data.data);
        setLoading(false);
      })
      .catch((fetchError) => {
        console.error(fetchError);
        setError(fetchError);
        setLoading(false);
      });
  }, []);

  const handleCategoryClick = (categoryId, categoryName) => {
    navigate(`/menu/${categoryId}`, { state: { categoryName } });
  };

  if (loading) return<div className="flex items-center justify-center h-screen bg-gray-100">
  <Loader2 className="w-12 h-12 text-[#8B0000] animate-spin" />
</div>;
  if (error) return <div>Error fetching data: {error.message}</div>;

  return (
    <div className="p-6 bg-[#8B0000] shadow-md rounded-md">
       <Link to='/' className="flex items-center mb-4 text-white">
              <ChevronLeft className="w-6 h-6 mr-2" />
      <h2 className="text-xl font-bold  text-white">Home</h2>
            </Link>
      {categories.length === 0 ? (<EmptyMenu />):
      (<div className="min-h-screen p-4">
        <div className="flex items-center mb-4">
          </div>
        <div className="max-w-4xl mx-auto">
          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4">
            {categories.map((category, index) => (
              <div
                onClick={() => handleCategoryClick(category.id, category.name)}
                key={index}
                className={`bg-white rounded-lg p-4 cursor-pointer transition duration-300 ease-in-out transform hover:scale-105 
                }`}
              >
                <h2 className="text-lg font-bold mb-2">{category.name}</h2>
                <div className={`grid grid-cols-1 gap-4`}>
                  <div className="relative overflow-hidden rounded-lg h-48">
                    <img
                      src={`http://137.184.71.161/${category.image}`}
                      alt={category.name}
                      className="w-full h-full object-cover"
                    />
                    <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white p-2">
                      <h3 className="font-bold">{category.name}</h3>
                      {category.price && <p className="text-sm">{category.price}</p>}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>)}
      
    </div>
  );
}

export default CategorySelector;
