import axios from "axios";

const BASE_URL = "http://137.184.71.161/api";

const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
  xsrfCookieName: 'XSRF-TOKEN',
  xsrfHeaderName: 'X-XSRF-TOKEN',
});

let isRefreshing = false;
let refreshSubscribers = [];

export const setAuthToken = (token) => {
  console.log('setAuthToken called with token:', token ? 'Token present' : 'No token');
  if (token) {
    localStorage.setItem("accessToken", token);
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    console.log('Token set in localStorage and api headers');
  } else {
    localStorage.removeItem("accessToken");
    delete api.defaults.headers.common['Authorization'];
    console.log('Token removed from localStorage and api headers');
  }
};

const refreshToken = async () => {
  console.log('Attempting to refresh token');
  try {
    const response = await axios.post(`${BASE_URL}/auth/refresh-token`, {}, {
      withCredentials: true
    });
    console.log('Refresh token response:', response.data);
    return response.data.accessToken;
  } catch (error) {
    console.error('Token refresh failed:', error.response?.data || error.message);
    throw error;
  }
};

const initializeAuthToken = () => {
  const token = localStorage.getItem("accessToken");
  console.log('Initializing auth token. Token in localStorage:', token ? 'Present' : 'Not present');
  if (token) {
    setAuthToken(token);
  }
};

initializeAuthToken();

api.interceptors.request.use(
  (config) => {
    console.log('Request interceptor:', config.url, 'Headers:', config.headers);
    return config;
  },
  (error) => {
    console.error('Request interceptor error:', error);
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    console.log('Response interceptor:', response.config.url, 'Status:', response.status);
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    console.error('API Error:', error.response?.status, error.response?.data, 'URL:', originalRequest.url);

    if (error.response?.status === 401 && !originalRequest._retry) {
      console.log('Unauthorized error detected. Attempting token refresh.');
      if (!isRefreshing) {
        console.log('Starting token refresh process');
        isRefreshing = true;
        originalRequest._retry = true;

        try {
          const newToken = await refreshToken();
          console.log('New token received:', newToken ? 'Token present' : 'No token');
          setAuthToken(newToken);
          
          originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
          
          console.log('Resolving pending requests:', refreshSubscribers.length);
          refreshSubscribers.forEach((callback) => callback(newToken));
          refreshSubscribers = [];
          
          console.log('Retrying original request');
          return api(originalRequest);
        } catch (refreshError) {
          console.error('Token refresh failed:', refreshError);
          setAuthToken(null);
          console.log('Redirecting to login page');
          // Implement your logout logic here
          // For example: window.location.href = '/login';
          return Promise.reject(refreshError);
        } finally {
          console.log('Token refresh process completed');
          isRefreshing = false;
        }
      } else {
        console.log('Token refresh already in progress. Adding request to queue.');
        return new Promise((resolve) => {
          refreshSubscribers.push((token) => {
            console.log('Resolving queued request with new token');
            originalRequest.headers['Authorization'] = `Bearer ${token}`;
            resolve(api(originalRequest));
          });
        });
      }
    }

    return Promise.reject(error);
  }
);

export { api };
export default api;
