import React from "react";
import { useNavigate } from "react-router-dom";
import cc_logo from "../Assets/img/cc_logo.jpg";
import kuIcon from "../Assets/img/Flag_of_Kurdistan.svg";
import arIcon from "../Assets/img/flag-400.png";
import enIcon from "../Assets/img/united-states-flag-icon.svg";

function LanguageSelector({ t, i18n }) {
  const navigate = useNavigate();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    navigate("/category");
  };

  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-b from-gray-900 to-gray-800">
      <div className="flex-1 flex flex-col justify-center items-center px-4 sm:px-6 lg:px-8">
        <img src={cc_logo} alt="CC Cafe & Restaurant" className="w-32 h-32 mb-8 filter " />
        <h1 className="text-4xl sm:text-5xl font-extrabold text-white mb-2">Welcome to</h1>
        <h2 className="text-2xl sm:text-3xl font-bold text-[#8B0000] mb-8">The CC Cafe & Restaurant</h2>
        <div className="relative text-white text-center mb-12">
          <p className=" font-semibold lg:-mb-4 text-left">Explore Our</p>
          <h3 className="text-7xl  lg:text-9xl font-bold  text-[#f8f8f8]">Menu</h3>
        </div>
        <h4 className="lg:text-2xl text-xl mb-2 font-bold text-white lg:-mb-8 md:-mb-36 md:mt-4 md:text-2xl lg:mt-8">Please Choose a Language</h4>
      </div>
      <div className="bg-[#8B0000] py-12">
        <div className="max-w-md mx-auto grid px-4 mb-12  gap-4">
          {[
            { lang: "en", text: "English", icon: enIcon },
            { lang: "ar", text: "عربی", icon: arIcon },
            { lang: "ku", text: "کوردی", icon: kuIcon },
          ].map((item) => (
            <button
              key={item.lang}
              onClick={() => changeLanguage(item.lang)}
              className=" flex items-center justify-between w-full px-6 py-3 text-lg font-medium text-white transition-all duration-200 bg-white bg-opacity-10 rounded-lg hover:bg-opacity-20 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-[#8B0000] focus:ring-white"
            >
              <span>{item.text}</span>
              <img src={item.icon} alt={item.text} className="w-8 h-8 object-contain" />
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

export default LanguageSelector;